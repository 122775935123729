import { Token } from "@/lib/entities/token.entity";
import appConfig from "configs/app";


export const useTokenIconSvg = (token: Token) => {
  if (!token) return "";
  switch (token.symbol) {
    case "pfastUSD":
      return appConfig.metaAssetHostUri + "/icons/tokens/pfastusd.svg";
    default:
      return token?.logo || "";
  }
};