import { StyleFunctionProps } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

import { mode } from "@chakra-ui/theme-tools";

const generateThemeFromColorScheme = (props: StyleFunctionProps) => {
  const { colorScheme } = props;
  let event;

  switch (colorScheme) {
    case "white":
      event = {
        bg: mode("neutral.stroke.light", "brand.light.shade")(props),
      };

      return {
        bg: mode("primary.black", "brand.primary")(props),
        color: mode("neutral.element.primary", "neutral.text.button")(props),
        _hover: event,
        _focus: event,
      };

    default:
      event = {
        bg: "brand.supper.light",
        color: "neutral.element.tertiary",
      };

      return {
        bg: "neutral.surface",
        color: "neutral.element.primary",
        _hover: event,
        _focus: event,
      };
  }
};

export const defaultVariant = defineStyle((props) => {
  return {
    ...generateThemeFromColorScheme(props),
    _disabled: {
      bg: mode("neutral.stroke.bold_light_theme", "neutral.stroke.bold")(props),
      color: mode("#7A7A7A", "#969696")(props),
      _hover: {
        bg: mode(
          "neutral.stroke.bold_light_theme",
          "neutral.stroke.bold"
        )(props),
        color: mode("#7A7A7A", "#969696")(props),
      },
    },
  };
});

export const outlineVariant = defineStyle((props) => {
  return {
    border: "1px solid",
    bg: "transparent",
    borderColor: mode("neutral.2", "brand.primary")(props),
    color: mode("neutral.2", "brand.primary")(props),
    _hover: {
      bgColor: mode("brand.light.shade", "neutral.surface")(props),
    },
    _selected: {
      bgColor: mode("brand.light.shade", "neutral.surface")(props),
    },
    _active: {
      bgColor: mode("brand.light.shade", "neutral.surface")(props),
    },
    _focus: {
      bgColor: mode("brand.light.shade", "neutral.surface")(props),
    },
  };
});

export const noneStyleVariant = defineStyle(() => {
  return {
    border: "none",
    bg: "transparent",
  };
});

const variants = {
  solid: defaultVariant,
  outline: outlineVariant,
  none: noneStyleVariant,
};

const baseStyle = defineStyle({
  px: "12px",
  py: "12px",
  overflow: "hidden",
  borderRadius: "12px",
  transition: "all 0.3s ease-in-out",
  fontWeight: 500,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  _focusVisible: {
    boxShadow: { base: "none", lg: "outline" },
  },
});

const sizes = {
  sm: {
    px: "12px",
    py: "12px",
    fontSize: "14px",
  },
  md: {
    px: "16px",
    py: "12px",
    fontSize: "16px",
  },
  lg: {
    px: "16px",
    py: "16px",
    fontSize: "18px",
  },
};

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: "md",
    variant: "solid",
    colorScheme: "blue",
  },
});

export default Button;
