import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { Modal as ModalComponent } from "@chakra-ui/react";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";
import { runIfFn } from "@chakra-ui/utils";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleDialog = defineStyle((props) => {
  return {
    margin: "auto",
    padding: "24px",
    borderRadius: "12px",
    bg: mode("neutral.on.surface.1", "neutral.on.surface.1")(props),
    boxShadow: "0px 32px 64px -12px #000",
  };
});

const baseStyleDialogContainer = defineStyle((props) => ({
  "::-webkit-scrollbar": { display: "none" },
  "scrollbar-width": "none",
  padding: 4,
  "& .chakra-modal__content": {
    display: "flex",
    flex: "initial",
    bgColor: mode("white", "neutral.surface.on.1")(props),
    border: "1px solid",
    borderColor: mode(
      "neutral.stroke.bold_light_theme",
      "neutral.stroke.bold"
    )(props),
    boxShadow: "0px 32px 64px -12px #000",
    width: "360px",
    gap: "24px",
  },
}));

const baseStyleHeader = defineStyle((props) => ({
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  color: mode("neutral.2", "neutral.element.primary")(props),
  fontSize: "24px",
  fontWeight: 700,
  marginY: 0,
  marginLeft: 0,
  marginRight: 8,
  padding: 0,
}));

const baseStyleBody = defineStyle({
  padding: 0,
  flex: "initial",
});

const baseStyleFooter = defineStyle({
  padding: 0,
  justifyContent: "flex-end",
});

const baseStyleCloseButton = defineStyle(() => {
  return {
    top: "1.5rem",
    right: "1.5rem",
    boxSize: 6,
    color: "neutral.element.primary",
    _hover: { color: "neutral.element.primary" },
    _active: { bg: "none" },
  };
});

const baseStyleOverlay = defineStyle({
  backgroundColor: "rgba(10, 10, 10, 0.20)",
});

const baseStyle = definePartsStyle((props) => ({
  dialog: runIfFn(baseStyleDialog, props),
  dialogContainer: runIfFn(baseStyleDialogContainer, props),

  header: runIfFn(baseStyleHeader, props),
  body: baseStyleBody,
  footer: baseStyleFooter,
  closeButton: runIfFn(baseStyleCloseButton),
  overlay: baseStyleOverlay,
}));

const sizes = {
  sm: definePartsStyle({
    dialogContainer: {
      height: "100%",
    },
    dialog: {
      maxW: "536px",
    },
  }),
  md: definePartsStyle({
    dialogContainer: {
      height: "100%",
    },
    dialog: {
      maxW: "760px",
    },
  }),
  full: definePartsStyle({
    dialogContainer: {
      height: "100%",
    },
    dialog: {
      maxW: "100vw",
      my: "0",
      borderRadius: "0",
      padding: "80px 16px 32px 16px",
      height: "100%",
      overflowY: "scroll",
    },
    closeButton: {
      top: 4,
      right: 6,
      width: 6,
      height: 6,
    },
    header: {
      mb: 6,
    },
  }),
};

const Modal = defineMultiStyleConfig({
  sizes,
  baseStyle,
});

export default Modal;

ModalComponent.defaultProps = {
  ...ModalComponent.defaultProps,
  isCentered: true,
};
