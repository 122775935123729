import { FC, useCallback, useEffect, useState } from "react";
import {
  Input,
  Button,
  Text,
  Flex,
  FlexProps,
  Image,
  Skeleton,
  InputProps,
  Tooltip,
  useColorModeValue,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TokenBalance } from "@/lib/entities/balance.entity";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { useAddWatchAssets } from "@/hooks/wallet/useAddWatchAssets";
import { NumberText } from "@/ui/Components/NumberText";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Cleave from "cleave.js/react";
import { countDecimals } from "@/utils/number";
import Decimal from "decimal.js";
import { useTokenIconSvg } from "@/hooks/useTokenIconSvg";

type CurrencyInputProps = {
  tokenBalance: TokenBalance;

  error?: string;
  self?: boolean;
  useWethGateway?: boolean;
  value?: AppNumber;
  decimalsScale?: number;
  headerInfo?: boolean;
  headerTitle?: string;
  isLoading?: boolean;
  headerProps?: FlexProps;
  isInputLoading?: boolean;
  hideEstimatedValue?: boolean;
  inputContentProps?: InputProps;
  isShowRateBar?: boolean;
  onPressMax?: () => void;
  onValueChange?(value: AppNumber): void;
};

export const CurrencyInput: FC<CurrencyInputProps> = ({
  value,
  self,
  useWethGateway,
  error,
  isLoading,
  isInputLoading,
  headerTitle,
  headerProps,
  tokenBalance,
  inputContentProps,
  hideEstimatedValue = false,
  headerInfo = false,
  decimalsScale = 18,
  isShowRateBar = false,

  onPressMax,
  onValueChange,
}) => {
  const { rpcSigner } = useSigner();
  const { addWatchAssets } = useAddWatchAssets();
  const { open: openWeb3Modal } = useWeb3Modal();
  const [estimatedValue, setEstimatedValue] = useState(AppNumber.from(0));

  // Update the ref to point to the actual input element
  // const ref = useRef(null);
  const [refName, setRefName] = useState("");

  const hideEstimatedValueLocal =
    hideEstimatedValue || tokenBalance?.tokenInfo?.isPitToken;
  const isAddTokenShown = !!rpcSigner?.address && !useWethGateway;

  const handleOnValueChange = useCallback(
    (val: AppNumber) => {
      onValueChange && onValueChange(val);
    },
    [onValueChange, tokenBalance]
  );

  const handleAddToken = useCallback(() => {
    if (!rpcSigner?.address) return openWeb3Modal({ view: "wallet" } as any);
    addWatchAssets(tokenBalance.tokenInfo)
      .then(() => console.info("Token added"))
      .catch((e) => console.warn("Error adding token", e));
  }, [tokenBalance, rpcSigner, openWeb3Modal, addWatchAssets]);

  const maskInput = useCallback(() => {
    if (!refName) return null;
    const decimals = tokenBalance?.tokenInfo?.decimals ?? decimalsScale;

    // ? Number(value.toString()).toFixed(countDecimals(value.toNumber()))

    return (
      <Cleave
        placeholder="0.00"
        options={{
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: decimals,
        }}
        value={
          value
            ? new Decimal(value.toString()).toFixed(
                countDecimals(value.toString())
              )
            : // .toFixed(countDecimals(value.toNumber()))
              undefined
        }
        className={refName}
        style={{ display: "block" }}
        disabled={isLoading || inputContentProps?.isDisabled}
        onChange={(e) => {
          e.preventDefault();
          const rawValue = e.target.rawValue;
          if (rawValue === "") {
            handleOnValueChange(AppNumber.from(0));
            return;
          }
          const numericValue = Number(rawValue);
          if (Number.isNaN(numericValue)) {
            return;
          }
          if (numericValue < 0) return;
          handleOnValueChange(AppNumber.from(rawValue));
        }}
      />
    );
  }, [refName, value, tokenBalance, useColorModeValue, handleOnValueChange]);

  useEffect(() => {
    if (value === undefined || value === null) return;
    setEstimatedValue(
      value.multiply(AppNumber.from(tokenBalance?.tokenInfo?.usdValue ?? 1))
    );
  }, [value]);

  return (
    <Skeleton isLoaded={!isLoading}>
      {headerTitle || headerInfo ? (
        <Flex
          mb={2}
          gap={2}
          {...headerProps}
          flexDirection={{ base: "row", lg: "column" }}
          justifyContent="space-between"
        >
          <Flex
            gap={2}
            alignItems={{ xl: "center" }}
            justifyContent={{ base: "flex-start", xl: "space-between" }}
            flexDirection={{
              base: "column",
              xl: "row",
            }}
          >
            <Flex alignItems="center" gap={2}>
              {headerTitle && (
                <Text colorScheme="secondary">{headerTitle}</Text>
              )}
              <Image
                src={
                  useTokenIconSvg(tokenBalance?.tokenInfo)
                }
                alt="Token logo"
                width="24px"
                height="24px"
              />
              <Text>
                {!useWethGateway ? tokenBalance?.tokenInfo?.symbol : "SEI"}
              </Text>
              {isAddTokenShown && (
                <Tooltip label="Add token to wallet">
                  <Image
                    src={`/icons/wallet/${useColorModeValue("light", "dark")}.svg`}
                    alt="Wallet"
                    width="16px"
                    height="16px"
                    cursor="pointer"
                    onClick={() => handleAddToken()}
                  />
                </Tooltip>
              )}
            </Flex>
            {self && rpcSigner?.address && (
              <Flex alignItems="center" gap={2}>
                <Text colorScheme="secondary">Balance</Text>
                <NumberText
                  value={tokenBalance?.balance ?? AppNumber.from(0)}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : null}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        w="full"
        borderRadius="12px"
        bg="neutral3"
        p="12px"
        py={hideEstimatedValueLocal ? "0" : "12px"}
        h={hideEstimatedValueLocal ? "auto" : "110px"}
        border="1px solid"
        bgColor={useColorModeValue(
          "neutral.stroke.light_theme",
          "neutral.stroke.light"
        )}
        borderColor={
          error
            ? "accent.error"
            : useColorModeValue(
                "neutral.element.secondary",
                "neutral.stroke.bold"
              )
        }
        gap={2}
        _focus={{
          borderColor:
            !error && useColorModeValue("neutral.2", "neutral.element.primary"),
        }}
        _hover={{
          borderColor:
            !error && useColorModeValue("neutral.2", "neutral.element.primary"),
        }}
      >
        <Skeleton isLoaded={!isInputLoading}>
          <Flex align="center" position="relative">
            <Input
              ref={(input) => setRefName(input?.className ?? "")}
              display="none"
              value={value ? value.toString() : undefined}
              placeholder="0.00"
              type="number"
              bgColor="transparent"
              border="none"
              focusBorderColor="transparent"
              _focus={{
                boxShadow: "none",
              }}
              _disabled={{
                bgColor: "transparent",
              }}
              textAlign="right"
              w={"full"}
              height="auto"
              py={2}
              fontSize="24px"
              fontWeight={700}
              color={useColorModeValue("neutral.2", "neutral.element.primary")}
              _placeholder={{
                color: useColorModeValue(
                  "neutral.element.tertiary_light_theme",
                  "neutral.element.tertiary2"
                ),
              }}
              onChange={() => {}}
              {...inputContentProps}
            />
            {maskInput()}
            {self && (
              <Button
                h="26px"
                px="12px"
                borderRadius="10px"
                bg={useColorModeValue("white", "neutral.on.surface.1")}
                border="1px solid"
                borderColor={useColorModeValue("neutral.2", "brand.primary")}
                _hover={{
                  bg: useColorModeValue(
                    "neutral.stroke.light_theme",
                    "neutral.on.surface.1"
                  ),
                }}
                _focus={{
                  bg: useColorModeValue(
                    "neutral.stroke.light_theme",
                    "neutral.on.surface.1"
                  ),
                }}
                onClick={() => {
                  if (!tokenBalance) return;
                  handleOnValueChange(tokenBalance.balance);
                  onPressMax && onPressMax();
                }}
              >
                <Text size="sm" fontWeight="bold">
                  Max
                </Text>
              </Button>
            )}
          </Flex>
        </Skeleton>
        {!hideEstimatedValueLocal ? (
          <Flex gap={2} justifyContent="flex-end" alignItems="center">
            <Text
              size="md"
              color={
                estimatedValue.lte(AppNumber.from(0))
                  ? useColorModeValue(
                      "neutral.element.tertiary_light_theme",
                      "neutral.element.tertiary"
                    )
                  : useColorModeValue("neutral.2", "neutral.element.primary")
              }
            >
              {`~${estimatedValue.toNumber().toFixed(2)}`}
            </Text>
            <Text size="sm" colorScheme="secondary">
              USD
            </Text>
          </Flex>
        ) : null}
      </Flex>
      {isShowRateBar && (
        <Grid
          mt="12px"
          columnGap="4px"
          templateColumns={{
            base: "repeat(4, 1fr)",
          }}
        >
          {[25, 50, 75, 100].map((percentage) => (
            <GridItem
              colSpan={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              borderRadius="12px"
              bg={useColorModeValue("brand.light.shade", "brand.supper.light")}
              p="4px"
              gap="8px"
              onClick={() => {
                if (!tokenBalance) return;
                handleOnValueChange(
                  tokenBalance.balance.multiply(
                    AppNumber.from(percentage / 100)
                  )
                );
              }}
            >
              <Text size="md" colorScheme="primary" fontWeight={700}>
                {percentage === 100 ? "Max" : `${percentage}%`}
              </Text>
            </GridItem>
          ))}
        </Grid>
      )}
      <Text size="sm" fontWeight={300} color="accent.error" textAlign="end">
        {error}
      </Text>
    </Skeleton>
  );
};
