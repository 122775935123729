import { getEnvValue } from "./utils";

const app = Object.freeze({
  web3_connect_project_id: getEnvValue("WEB3_CONNECTOR_PROJECT_ID"),
  meta_data: {
    title: getEnvValue("META_DATA_TITLE"),
    description: getEnvValue("META_DATA_DESCRIPTION"),
  },

  api_uri: [
    `${getEnvValue("APP.PROTOCOL")}://`,
    `${getEnvValue("APP.HOST")}`,
    `${getEnvValue("APP.PORT") ? `:${getEnvValue("APP.PORT")}` : ""}`,
    '/daemon/v1/api',
  ].join(""),

  app_uri: [
    `${getEnvValue("APP.PROTOCOL")}://`,
    `${getEnvValue("APP.HOST")}`,
    `${getEnvValue("APP.PORT") ? `:${getEnvValue("APP.PORT")}` : ""}`,
  ].join(""),

  firebase: {
    apiKey: getEnvValue("FIREBASE.apiKey"),
    authDomain: getEnvValue("FIREBASE.authDomain"),
    projectId: getEnvValue("FIREBASE.projectId"),
    storageBucket: getEnvValue("FIREBASE.storageBucket"),
    messagingSenderId: getEnvValue("FIREBASE.messagingSenderId"),
    appId: getEnvValue("FIREBASE.appId"),
    measurementId: getEnvValue("FIREBASE.measurementId"),
  },

  metaAssetHostUri: getEnvValue("META_ASSET_HOST_URI"),
});

export default {
  ...app,
  meta_data: {
    ...app.meta_data,
    icon: `${app.app_uri}/assets/icons/favicon-per.png`,
  },
};
