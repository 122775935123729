import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import appConfig from "configs/app";

export function useFirebase() {
  const firebaseConfig = appConfig.firebase;
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  return db;
}
