import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: "neutral.element.primary",
    height: "24px",
    width: "24px",
    _checked: {
      bg: "neutral.surface",
    },
  },
  track: {
    bg: "neutral.surface",
    borderRadius: "39px",
    border: "1px solid",
    borderColor: "neutral.element.primary",

    width: "84px",
    height: "32px",

    _checked: {
      bg: "neutral.element.primary",
      borderColor: "neutral.surface",
    },
  },
});

const Switch = defineMultiStyleConfig({ baseStyle });

export default Switch;
