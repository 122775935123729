import { FC } from "react";
import { Divider } from "./Divider";
import { Box, Flex, Text, Link, Image, chakra } from "@chakra-ui/react";
import {
  FOOTER_MENU_GROUPS,
  FOOTER_SOCIAL_ITEMS,
  type FooterSocialItem as IFooterSocialItem,
} from "@/types/home";
import { LayoutSectionCard } from "./LayoutSectionCard";

const FooterSocialItem: FC<IFooterSocialItem> = ({ icon, href }) => (
  <chakra.a href={href} target="_blank" rel="noreferrer">
    <Image src={icon} alt="social" className="w-[24px] h-[24px]" />
  </chakra.a>
);

export const Footer: FC = () => {
  return (
    <Flex bgColor="neutral.on.surface.1">
      <LayoutSectionCard
        pt={{ base: "80px", lg: "60px" }}
        pb={{ base: "16px", lg: "24px" }}
        flexDirection="column"
        as="footer"
        gap={0}
      >
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent={{ lg: "space-between" }}
          rowGap="32px"
        >
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent={{ base: "center", lg: "flex-start" }}
            rowGap="32px"
            columnGap="24px"
          >
            {FOOTER_MENU_GROUPS.map((group, index) => (
              <Flex
                key={`${group.title}-${Math.random()}-${index}`}
                flexDirection="column"
                width={{ base: "full", lg: "240px" }}
                rowGap="12px"
              >
                <Text
                  size="xl"
                  fontWeight={700}
                  textAlign={{ base: "center", lg: "initial" }}
                >
                  {group.title}
                </Text>
                <chakra.ul
                  rowGap="8px"
                  display="flex"
                  columnGap="24px"
                  flexDirection={{ base: "row", lg: "column" }}
                  justifyContent={{ base: "center", lg: "initial" }}
                >
                  {group.items.map((item, index) => (
                    <chakra.li key={`${item.title}-${Math.random()}-${index}`}>
                      <Link
                        href={item.href}
                        color="neutral.element.secondary"
                        target={item.isExternal ? "_blank" : "_self"}
                      >
                        {item.title}
                      </Link>
                    </chakra.li>
                  ))}
                </chakra.ul>
              </Flex>
            ))}
          </Flex>
          <Flex
            gap="24px"
            justifyContent={{ base: "center", lg: "flex-start" }}
          >
            {FOOTER_SOCIAL_ITEMS.map((item, index) => (
              <FooterSocialItem
                key={`${item.icon}-${Math.random()}-${index}`}
                icon={item.icon}
                href={item.href}
              />
            ))}
          </Flex>
        </Flex>
        <Divider className="my-[32px]" />
        <Box display={{ md: "flow-root" }}>
          <Box float={{ md: "left" }} textAlign="center">
            <Link href="/" className="mobile:inline-block">
              <Image
                src="/icons/logo-dark.svg"
                alt="logo"
                className="w-[70px] h-auto"
              />
            </Link>
          </Box>
          <Box float={{ md: "right" }}>
            <Text
              color="neutral.element.tertiary"
              size="sm"
              textAlign={{ base: "center", md: "initial" }}
            >
              © 2024 Pit.Finance. All rights reserved
            </Text>
          </Box>
        </Box>
      </LayoutSectionCard>
    </Flex>
  );
};
