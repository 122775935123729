import {
  Text,
  Flex,
  Image,
  UseDisclosureReturn,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Divider } from "@/ui/Components/Divider";
import { AddressEntry } from "@/ui/Components/AddressEntry";
import { useDisconnect } from "wagmi";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { HeaderProfileMenuBalances } from "./HeaderProfileMenuBalances";
import { WalletAvatar } from "@/ui/Components/WalletAvatar";
import { useChain } from "@/hooks/Web3ModalProvider";

type Props = {
  isLoading?: boolean;
  wrapModalProps: Pick<UseDisclosureReturn, "isOpen" | "onClose" | "onOpen">;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose" | "onOpen">;

const HeaderProfileMenuContent = ({ onClose, wrapModalProps }: Props) => {
  const { disconnect } = useDisconnect();
  const { rpcSigner } = useSigner();
  const { desiredChain } = useChain();

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      width="full"
      overflow="hidden"
      gap="24px"
    >
      <Flex alignItems="center" gap={1}>
        <WalletAvatar
          seed={rpcSigner?.address || ""}
          alt="Picture of the author"
          width="40px"
          height="40px"
        />
        <AddressEntry
          entity={{ address: String(rpcSigner?.address || "") }}
          truncation="tail"
          textProps={{ size: "xl" }}
          truncationData={{
            headLength: 6,
            tailLength: 4,
          }}
        />
        <Image
          src={`/icons/share/${useColorModeValue("light", "dark")}.svg`}
          alt="Arrow up right"
          width="24px"
          height="24px"
          cursor="pointer"
          onClick={() =>
            global.window.open(
              desiredChain.addressUrl(rpcSigner?.address),
              "_blank"
            )
          }
        />
      </Flex>
      <Divider />
      <HeaderProfileMenuBalances />
      <Divider />
      <Flex
        gap={2}
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          onClose();
          wrapModalProps.onOpen();
        }}
      >
        <Image
          src={`/icons/wrap/${useColorModeValue("light", "dark")}.svg`}
          alt="wrap"
          width="24px"
          height="24px"
        />
        <Text>Wrap/Unwrap SEI</Text>
      </Flex>
      <Flex
        gap={2}
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          onClose();
          disconnect();
        }}
      >
        <Image
          src="/icons/disconnect.svg"
          alt="wrap"
          width="24px"
          height="24px"
        />
        <Text color="accent.error">Disconnect</Text>
      </Flex>
    </Flex>
  );
};

export default HeaderProfileMenuContent;
