import { Box, FormLabel, Text, Input, useColorMode } from "@chakra-ui/react";
import React, { useState } from "react";
import { FC } from "react";

export const HeaderColorModeSwitcher: FC = () => {
  const { colorMode: themeMode, toggleColorMode } = useColorMode();

  return (
    <FormLabel
      htmlFor="theme-switcher"
      padding={0}
      margin={0}
      as={"label"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      w="84px"
      h="32px"
      borderRadius="39px"
      border="1px solid"
      borderColor={
        themeMode === "dark" ? "neutral.element.primary" : "neutral.surface"
      }
      bg={themeMode === "dark" ? "neutral.surface" : "neutral.element.primary"}
      cursor="pointer"
    >
      <Input
        id="theme-switcher"
        type="checkbox"
        checked={themeMode.includes("light") ? true : false}
        onChange={() => toggleColorMode()}
        display="inline-block"
        appearance="none"
        visibility="hidden"
        cursor="pointer"
        w="84px"
        h="32px"
      />
      <Text
        position="absolute"
        fontSize="13px"
        left={themeMode === "dark" ? "unset" : "14px"}
        right={themeMode === "dark" ? "14px" : "unset"}
        color={
          themeMode === "dark" ? "neutral.element.primary" : "neutral.surface"
        }
      >
        {themeMode.replace("l", "L").replace("d", "D")}
      </Text>
      <Box
        className={`iconMove`}
        transition="all 0.2s ease-in"
        transform={`${themeMode.includes("dark") ? "translateX(-26px)" : "translateX(26px)"}`}
        position="absolute"
        cursor="pointer"
        w="24px"
        h="24px"
        bg={
          themeMode === "dark" ? "neutral.element.primary" : "neutral.surface"
        }
        borderRadius="full"
      />
    </FormLabel>
  );
};
