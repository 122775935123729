import {
  AppNumber,
  LARGE_NUMBER,
  ROUND_DECIMAL_PLACES,
} from "@/lib/providers/math/app-number.provider";
import { countDecimals } from "@/utils/number";
import {
  TextProps,
  Tooltip,
  Text,
  TooltipProps,
  Skeleton,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = TextProps & {
  toolTipProps?: TooltipProps;
  isLoading?: boolean;
  value: AppNumber;
  content?: string;
  isShowDollar?: boolean;
  roundData?: {
    minimumToFormat?: number; //10000,
    precisionRound?: number; // ROUND_DECIMAL_PLACES
    isFormatLargeDecimals?: boolean;
  };
};

export const NumberText: FC<Props> = ({
  value,
  toolTipProps,
  isLoading,
  content,
  isShowDollar = false,
  roundData = {
    minimumToFormat: LARGE_NUMBER.kk,
    precisionRound: ROUND_DECIMAL_PLACES,
    isFormatLargeDecimals: true,
  },
  ...props
}) => {
  const realValue = value ? value.toString() : String(0);
  const roundedValue = value
    ? value.getDisplayedString(
        roundData.minimumToFormat,
        roundData.precisionRound,
        roundData.isFormatLargeDecimals
      )
    : 0;

  const isHasTooltip = realValue !== roundedValue;
  const tooltipVal =
    realValue.split(".").length > 1
      ? `${parseInt(realValue.split(".")[0]).toLocaleString("en-US")}.${realValue.split(".")[1]}`
      : realValue;

  return (
    <Tooltip label={isHasTooltip ? `${tooltipVal}` : ""} {...toolTipProps}>
      <Skeleton isLoaded={!isLoading}>
        <Text {...props}>
          {isShowDollar && `$`}
          {roundedValue}
          {content && ` ${content}`}
        </Text>
      </Skeleton>
    </Tooltip>
  );
};
