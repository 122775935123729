import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { Text } from "@/ui/Components/Text";
import { ReactNode } from "react";
import { toast as toastToastify } from "react-toastify";
import { capitalizeFirstLetter } from "@/utils/dom";

export enum ToastType {
  success = "success",
  error = "error",
  reject = "reject",
  warning = "warning",
  info = "info",
}

type Props = {
  type?: ToastType;
  title?: string;
  children?: string | ReactNode;
  description?: string;

  explorerUrl?: string;
  normalUrl?: string;

  theme?: "light" | "dark";
};

export const toast = ({
  type = ToastType.info,
  title,
  children,
  normalUrl,
  explorerUrl,
  description,
  theme = "dark",
}: Props) => {
  let promt;

  switch (type) {
    case ToastType.success:
      promt = toastToastify.success;
      break;
    case ToastType.error:
      promt = toastToastify.error;
      break;
    case ToastType.warning:
      promt = toastToastify.warning;
      break;
    case ToastType.info:
    default:
      promt = toastToastify.info;
      break;
  }

  const makeIconUri = () => {
    switch (type) {
      case ToastType.success:
        return "/icons/status/success.svg";
      case ToastType.error:
        return "/icons/status/error.svg";
      case ToastType.warning:
        return "/icons/status/warning.svg";
      case ToastType.info:
      default:
        return "/icons/status/info.svg";
    }
  };

  promt(
    ({ closeToast }) => {
      let interval: NodeJS.Timeout;
      const intervalExecute = () => {
        interval = setTimeout(() => closeToast(), 4000);
      };

      // First execute interval.
      intervalExecute();

      return (
        <Flex
          position="relative"
          onMouseEnter={() => interval && clearInterval(interval)}
          onMouseLeave={() => intervalExecute()}
        >
          <Box
            position="absolute"
            right="10px"
            top="0xp"
            onClick={closeToast}
            style={{ zIndex: 2 }}
          >
            <Image
              src={`/icons/close/${theme}.svg`}
              alt="close"
              width="24px"
              height="24px"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Flex alignItems="flex-start" gap={2}>
            <Image src={makeIconUri()} alt="icon" width="24px" height="24px" />
            <Flex
              h="full"
              flexDirection="column"
              justifyContent="space-between"
              gap={2}
            >
              <Flex flexDirection="column" gap={2}>
                <Text size="md" fontWeight={700}>
                  {title || capitalizeFirstLetter(type.toString())}
                </Text>
                {description || typeof children === "string" ? (
                  <Text size="md" colorScheme="secondary" fontWeight={700}>
                    {description ?? children}
                  </Text>
                ) : (
                  children
                )}
              </Flex>
              {(explorerUrl || normalUrl) && (
                <Link
                  gap={2}
                  href={explorerUrl || normalUrl}
                  colorScheme="primary"
                  display="flex"
                  alignItems="center"
                  target="_blank"
                  fontWeight={500}
                >
                  View on {explorerUrl ? "Seitrace" : "website"}
                  <Image
                    src={`/icons/share/${theme}.svg`}
                    alt="Share"
                    width="20px"
                    height="20px"
                  />
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      );
    },
    { theme }
  );
};
