import { useVault } from "@/hooks/vault/useVault";
import { useChain } from "@/hooks/Web3ModalProvider";
import { Token } from "@/lib/entities/token.entity";
import { useAppDispatch } from "@/redux/store";
import { updateMultipleTokens } from "@/redux/whitelisted-token.state";
import { useCallback } from "react";

export const useInitTokens = () => {
  const dispatch = useAppDispatch();
  const vaultQueryData = useVault();
  const { desiredChain } = useChain();

  const updateTokens = useCallback(() => {
    // Extract token list
    const { vaults } = vaultQueryData;
    const tokens = Object.values(vaults)
      .filter((vault) => !vault.isDummyData)
      .map((vault) => {
        return Token.fromVaultInfo(vault);
      })
      .reduce((acc, [token, pToken]) => [...acc, token, pToken], [] as Token[]);

    // Update tokens in redux
    dispatch(
      updateMultipleTokens([
        Token.fromRawEntity({
          ...desiredChain.nativeToken,
          usdValue:
            tokens.find((token) => token.symbol === "WSEI")?.usdValue ?? 0,
        }),
        desiredChain.wrappedNativeToken,
        ...tokens,
      ])
    );
  }, [vaultQueryData, desiredChain]);

  return { updateTokens };
};
