import { useEffect, useState } from "react";
import { collection, addDoc } from "firebase/firestore";

import { useFirebase } from "@/hooks/useFirebase";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { useInterval } from "usehooks-ts";

const IS_WALLET_LOGGED = "IS_WALLET_LOGGED";
interface LogCache {
  created_date: Date;
  wallet_address: string;
  connector_name: string;
}

export const WalletConnection = () => {
  const { rpcSigner, connector, isConnected, isDisconnected } = useSigner();
  const firebase = useFirebase();
  const [enabledToSave, setEnabledToSave] = useState(false);

  const storeConnectionLog = async (log: LogCache) => {
    addDoc(collection(firebase, "connectionLog"), log)
      .then((docRef) => console.info("Document written with ID: ", docRef.id))
      .catch((e) => console.warn("Error adding document: ", e));
  };

  useEffect(() => {
    if (
      connector &&
      rpcSigner?.address &&
      (enabledToSave ||
        globalThis?.localStorage?.getItem(IS_WALLET_LOGGED) !== "true")
    ) {
      globalThis?.localStorage?.setItem(IS_WALLET_LOGGED, "true");
      setEnabledToSave(false);
      storeConnectionLog({
        created_date: new Date(),
        wallet_address: rpcSigner?.address,
        connector_name: connector.name,
      });
    }
  }, [isConnected, connector, rpcSigner]);

  useInterval(() => {
    if (isDisconnected) {
      setEnabledToSave(true);
    }
  }, 2000);

  return <></>;
};
