import { TokenBalance } from "@/lib/entities/balance.entity";
import {
  createContext,
  ReactNode,
  FC,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useSigner } from "./SignerProvider";
import { ethers, JsonRpcProvider } from "ethers";
import { ERC20_ABI } from "@/lib/constants/web3";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  getSingleTokenBalanceQuery,
  getTokenBalanceQuery,
  updateMultipleTokenBalances,
} from "@/redux/balance.state";
import { useSelector } from "react-redux";
import { useChain } from "@/hooks/Web3ModalProvider";
import { getVaultsQuery } from "@/redux/vault.state";
import { getWhitelistedTokens } from "@/redux/whitelisted-token.state";
import { useInterval } from "usehooks-ts";

export const BalanceContext = createContext<{
  balances: Record<string, TokenBalance>;
  isFetching: boolean;
  getBalances(force?: boolean): void;
}>(null);

export const BalanceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { desiredChain } = useChain();

  const balances = useSelector(getTokenBalanceQuery);
  const vaults = useSelector(getVaultsQuery);
  const {
    rpcSigner,
    rpcProvider,
    service: { signerTokenService },
  } = useSigner();
  const whitelistedTokens = useAppSelector(getWhitelistedTokens);
  const [memonized, setMemonized] = useState("");

  const [isFetching, setIsFetching] = useState(false);

  const fetchAllowanceFromContract = async (
    provider: JsonRpcProvider,
    walletAddress: string,
    tokenAddress: string,
    contractAddress: string
  ) => {
    try {
      return await new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        provider
      ).allowance(walletAddress, contractAddress);
    } catch {
      return 0;
    }
  };

  const fetchBalanceFromContract = async (
    provider: JsonRpcProvider,
    contractAddress: string
  ) => {
    try {
      return await new ethers.Contract(
        contractAddress,
        ERC20_ABI,
        provider
      ).balanceOf(rpcSigner.address);
    } catch (err) {
      console.warn("Error fetching balance", err);
      return 0;
    }
  };

  const getBalances = useCallback(
    async (force = false) => {
      setIsFetching(true);
      Promise.all(
        [...Object.values(whitelistedTokens)].map(async (token) => {
          const tokenInfo = signerTokenService.getTokenEntity(
            token.contractAddress
          );

          if (!rpcProvider || !rpcSigner) {
            return TokenBalance.from({
              allowances: {},
              balance: new AppNumber(0),
              rawBalance: new AppNumber(0),
              tokenInfo,
            });
          }

          if (token.isGasToken) {
            const rawNativeBalance = AppNumber.from(
              (await rpcProvider.getBalance(rpcSigner.address)) ?? 0
            );
            return TokenBalance.from({
              allowances: {},
              rawBalance: rawNativeBalance,
              balance: await rawNativeBalance.getRealTokenAmount(
                token.decimals
              ),
              tokenInfo,
            });
          }

          const balance = await fetchBalanceFromContract(
            rpcProvider,
            token.contractAddress
          );

          const allowances = (
            await Promise.all(
              Object.keys(vaults).map(async (vaultAddress) => {
                const vaultAllowance = AppNumber.from(
                  await fetchAllowanceFromContract(
                    rpcProvider,
                    rpcSigner.address,
                    token.contractAddress,
                    vaultAddress
                  )
                );
                const r = {
                  [vaultAddress]: vaultAllowance,
                };
                if (vaults[vaultAddress].wethGatewayAddress) {
                  r[vaults[vaultAddress].wethGatewayAddress] = AppNumber.from(
                    await fetchAllowanceFromContract(
                      rpcProvider,
                      rpcSigner.address,
                      token.contractAddress,
                      vaults[vaultAddress].wethGatewayAddress
                    )
                  );
                }
                return r;
              })
            )
          ).reduce((acc, val) => ({ ...acc, ...val }), {});

          // Fetch allowance for the token itself
          allowances[token.contractAddress] = AppNumber.from(
            await fetchAllowanceFromContract(
              rpcProvider,
              rpcSigner.address,
              token.contractAddress,
              token.contractAddress
            )
          );

          return TokenBalance.from({
            allowances,
            balance: AppNumber.from(balance).getRealTokenAmount(token.decimals),
            rawBalance: new AppNumber(balance),
            tokenInfo,
          });
        })
      )
        .then((balances) => {
          // balances.map((balance) => console.log("Token: ", balance.tokenInfo.symbol, "allowances: ", Object.keys(balance.allowances).map((key) => balance.allowances[key].toString())));
          dispatch(updateMultipleTokenBalances(balances));
        })
        .catch((error) => {
          console.error("Error fetching balances", error);
        })
        .finally(() => setIsFetching(false));
    },
    [
      rpcSigner,
      rpcProvider,
      desiredChain,
      signerTokenService,
      whitelistedTokens,
      memonized,
      setIsFetching,
    ]
  );

  useEffect(() => {
    getBalances();
  }, [
    rpcSigner,
    rpcProvider,
    desiredChain,
    signerTokenService,
    whitelistedTokens,
    memonized,
    setIsFetching,
  ]);

  useInterval(() => getBalances(true), 1000 * 60 * 1);

  return (
    <BalanceContext.Provider value={{ balances, isFetching, getBalances }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalances = () => {
  const context = useContext(BalanceContext);
  if (!context) throw new Error("Must be in provider");
  return context;
};

export const useGetBalances = () => {
  const context = useContext(BalanceContext);
  if (!context) throw new Error("Must be in provider");
  return context;
};

export const useSingleBalance = (address: string) => {
  return useSelector((state) => getSingleTokenBalanceQuery(state, address));
};
