import { useChain } from "../Web3ModalProvider";
import { useCallback } from "react";
import { EVM_ADDRESS } from "@/types/web3";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { TransactionMethod } from "@/hooks//useTransaction";
import { useContract } from "./useContract";

export const useUnwrapSei = () => {
  const { desiredChain } = useChain();
  const { executeContract, setLocalAmount, isSuccess, receipt, ...left } =
    useContract(TransactionMethod.Unwrap);

  const unwrap = useCallback(
    async (amount: AppNumber) => {
      setLocalAmount(amount);
      await executeContract({
        address: desiredChain.nativeToken.wrappedTokenAddress as EVM_ADDRESS,
        abi: [
          {
            name: "withdraw",
            type: "function",
            stateMutability: "nonpayable",
            inputs: [
              {
                internalType: "uint256",
                name: "wad",
                type: "uint256",
              },
            ],
            outputs: [],
          },
        ],
        functionName: "withdraw",
        args: [amount ?? 0n],
      } as any);
    },
    [desiredChain, executeContract]
  );

  return { ...left, unwrap, isSuccess: isSuccess && receipt };
};
