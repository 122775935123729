import { toast, ToastType } from "@/ui/toast";
import { useColorModeValue } from "@chakra-ui/react";
import { useCallback } from "react";

export const useClipboard = () => {
  const theme = useColorModeValue("light", "dark");

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text).then(
        () => {
          toast({
            type: ToastType.info,
            title: "Copied to clipboard",
            theme,
          });
        },
        () => {
          toast({
            type: ToastType.error,
            title: "Failed to copy to clipboard",
            theme,
          });
        }
      );
    },
    [theme]
  );

  return {
    copyToClipboard,
  };
};
