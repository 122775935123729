import { FC, useRef, useState } from "react";
import {
  Image,
  ImageProps,
  Skeleton,
  Text,
  TextProps,
  Tooltip,
  TooltipProps,
  useColorModeValue,
} from "@chakra-ui/react";

type Props = TextProps & {
  text: string;
  tooltipDesc: string;
  isLoading?: boolean;
  tooltipProps?: TooltipProps;
  infoIconProps?: ImageProps;
};

export const TextTooltip: FC<Props> = ({
  text,
  isLoading,
  tooltipDesc,
  tooltipProps,
  infoIconProps,
  ...textProps
}) => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Text
        {...textProps}
        ref={ref}
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent={textProps.textAlign ?? "flex-start"}
        onMouseMove={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {text}
        <Tooltip {...tooltipProps} label={tooltipDesc} isOpen={isOpen}>
          <Image
            src={`/icons/info/${useColorModeValue("light", "dark")}.svg`}
            alt="info"
            width="20px"
            height="20px"
            {...infoIconProps}
          />
        </Tooltip>
      </Text>
    </Skeleton>
  );
};
