import {
  Button,
  ButtonProps,
  Center,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, memo } from "react";

const HeaderHamburgerButton = (
  { onClick, isOpen, ...props }: Partial<ButtonProps> & { isOpen: boolean },
  ref?: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <Button
      order={4}
      as={Center}
      borderRadius="100%"
      variant="unstyled"
      onClick={onClick}
      display="flex"
      width="40px"
      height="40px"
      cursor="pointer"
      {...props}
      ref={ref}
      border="1px solid"
      borderColor={useColorModeValue("neutral.2", "neutral.element.primary")}
    >
      <Image
        src={`/icons/hamburger/${useColorModeValue("light", "dark")}/${isOpen ? "close" : "open"}.svg`}
        alt="Close"
        width="30px"
        height="30px"
      />
    </Button>
  );
};

export default memo(forwardRef(HeaderHamburgerButton));
