import { ToastType } from "@/ui/toast";
import { useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { TransactionMethod, useTransaction } from "@/hooks//useTransaction";
import { TokenInfo } from "@/lib/entities/token.entity";
import { useBalances } from "./BalanceProvider";
import { safeStringify } from "@/utils/number";

export type Event = {
  onSuccess?(receipt: { [x: string]: any }): void;
  onFailed?(receipt: { [x: string]: any }): void;
};

export function useContract(
  method: TransactionMethod,
  events: Event = {
    onSuccess: () => {},
    onFailed: () => {},
  }
) {
  const { onSuccess, onFailed } = events;

  const { addTransaction } = useTransaction();
  const { getBalances } = useBalances();
  const {
    data: hash,
    isIdle,
    isPending,
    error,
    isError,
    isSuccess,
    writeContract,
    writeContractAsync,
  } = useWriteContract();

  const { data: receipt } = useWaitForTransactionReceipt({ hash });

  const [localAmount, setLocalAmount] = useState<AppNumber>(AppNumber.from(0));
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>();

  const [cachedReceipt, setCachedReceipt] = useState({});

  const executeContract = useCallback(
    async (args: any) => {
      try {
        await writeContractAsync(args);
      } catch (e) {
        onFailed && onFailed(receipt);
        addTransaction({
          hash,
          method,
          token: tokenInfo,
          type: ToastType.error,
          description: (e as any)?.shortMessage,
          value: localAmount.getRealTokenAmount(tokenInfo?.decimals ?? 18),
        });
      }
    },
    [isError, tokenInfo, receipt, hash, onFailed, method]
  );

  useEffect(() => {
    if (!isSuccess || !receipt) return;
    if (safeStringify({ receipt }) === safeStringify(cachedReceipt)) {
      return;
    }

    console.info("Successfully: ", receipt);
    setCachedReceipt({ receipt });
    onSuccess && onSuccess(receipt);
    getBalances(true); // Refresh balances after transaction
    addTransaction({
      hash,
      method,
      token: tokenInfo,
      type: ToastType.success,
      value: localAmount.getRealTokenAmount(tokenInfo?.decimals ?? 18),
    });
  }, [isSuccess, tokenInfo, receipt, hash, method]);

  return {
    error: error as any,
    isPending: !hash ? isPending : receipt === undefined,

    hash,
    isIdle,
    isError,
    receipt,
    isSuccess,
    localAmount,
    executeContract,
    setTokenInfo,
    writeContract,
    setLocalAmount,
    writeContractAsync,
  };
}
